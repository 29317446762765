import PubSub from 'vanilla-pubsub';
import lottie from 'lottie-web';
import IntersectionEvent from '@/js/modules/IntersectionEvent';

import tsukuruAnimation from '@/assets/lottie/tsukuru.json';
import tsunageruAnimation from '@/assets/lottie/tsunageru.json';
import tsutaeruAnimation from '@/assets/lottie/tsutaeru.json';

export default class LottieEffect {
  constructor(selector) {
    this.selector = selector;
    this.el = document.querySelectorAll(selector);
    this.options = {
      publishName: 'intersectionEvent.intersectLottie',
    };
    this.animationData = {
      light: tsukuruAnimation,
      handshake: tsunageruAnimation,
      global: tsutaeruAnimation,
    };
    this.instances = {};

    if (this.el) {
      this._initialize();
      this._bind();
    }
  }

  _initialize() {
    this.el.forEach((it) => {
      const id = it.dataset.lottie;
      const autoplay = it.dataset.autoplay || false;
      const animation = this.animationData[id];
      if (id && animation) {
        const animationItem = lottie.loadAnimation({
          container: it,
          renderer: 'svg',
          loop: false,
          autoplay,
          animationData: animation,
        });
        this.instances[it.dataset.lottie] = animationItem;
      }
    });
    this.observers = new IntersectionEvent(this.selector, { publishName: this.options.publishName });
  }

  _bind() {
    this.onIntersect = this.onIntersect.bind(this);
    PubSub.subscribe(this.options.publishName, this.onIntersect);
  }

  async onIntersect(entry) {
    // 交差外
    if (!entry.isIntersecting) {
      return;
    }
    const id = entry.target.dataset.lottie;
    const delay = parseFloat(entry.target.dataset.delay || 0);
    const animationItem = this.instances[id];
    setTimeout(() => {
      animationItem.setSpeed(1);
      animationItem.play();
    }, delay);
  }
}
