import React from 'react';
import { render } from 'react-dom';
import { TaglineAnimation } from './TaglineAnimation';

export default class {
  selector: string;
  el: HTMLElement | null = null;
  setupFailed: number;

  constructor(selector: string) {
    this.selector = selector;
    this.setupFailed = 0;
    this.setup();
  }

  setup() {
    this.el = document.querySelector(this.selector);
    // const target = document.querySelector(this.el?.dataset.target || '') as HTMLElement | undefined;

    if (this.el) {
      render(<TaglineAnimation />, this.el);
    }
  }
}
