import '@/styles/style.scss';

// Libs
import PubSub from 'vanilla-pubsub';

// Utils
import { getParsedUA } from './utils/browser';
import { NODE_ENV } from './utils/env';

// Modules
import Viewport from './modules/Viewport';
import HashPosition from './modules/HashPosition';
import DisableScroll from './modules/DisableScroll';
import SmoothScroll from './modules/SmoothScroll';
import SmoothScrollURLAdapter from './modules/SmoothScrollURLAdapter';
import Menu from './modules/Menu';
import Pagetop from './modules/Pagetop';
import CookieDialog from './modules/CookieDialog';
import SimpleCopyGuard from './modules/SimpleCopyGuard';
import TaglineAnimation from '@/js/components/TaglineAnimation';
import LottieEffect from '@/js/modules/LottieEffect';

// Components
import TurnTable from './components/TurnTable';

export type ChunkMap = {
  [key: string]: string;
};

export type Modules = {
  [key: string]: any;
};

// ProjectClass (singleton)
import project from './project';

// Mocks
import initMockServer from '@/js/mocks';

class App {
  isDev: boolean;
  pages: ChunkMap;
  globals: Modules;
  currentPage: any;

  constructor() {
    this.isDev = NODE_ENV !== 'production';
    this._initialize();

    // for dynamic import
    this.pages = {
      top: 'top',
      archive: 'archive',
      post: 'post',
      service: 'service',
      serviceDetail: 'serviceDetail',
      tagline: 'tagline',
      company: 'company',
      news: 'news',
      contact: 'contact',
      document: 'document',
    };

    this.globals = {};

    window.addEventListener('DOMContentLoaded', () => {
      this._setup();
    });
  }

  _initialize() {
    // Define Browser UA
    const results = getParsedUA();
    document.documentElement.classList.add(results.browser.name.replace(/\s/, ''));

    if (results.os.name) {
      const os = results.os.name.replace(/\s/, '').toLowerCase();
      document.documentElement.classList.add(os);
    }

    if (/safari/i.test(results?.browser?.name || '') && /^14\.0/.test(results?.browser?.version || '')) {
      document.documentElement.classList.add('legacy');
      document.documentElement.classList.add('flex-gap-not-supported');
    }
  }

  async _setup() {
    document.body.classList.add('is-content-ready');

    // 現在ページを取得
    const $root = document.querySelector('.js-root') as undefined | HTMLElement;

    if (!$root) {
      console.warn('.js-root[data-page="<chunkName>"] が未指定です');
      return;
    }

    const current = $root.dataset.page || '';

    if (current === 'top') {
      new LottieEffect('.js-lottie');
      new TaglineAnimation('.js-taglineAnimation');
    }

    if (this.isDev) {
      await initMockServer();
    }

    // for #311
    const isConfirm = /contact\/confirm/.test(window?.location.href);

    // ページ共通で実行されるjs群
    this.globals = {
      viewport: new Viewport(),
      hashPosition: isConfirm ? null : new HashPosition(),
      disableScroll: new DisableScroll(),
      smoothScroll: isConfirm ? null : new SmoothScroll(),
      smoothScrollURLAdapter: isConfirm ? null : new SmoothScrollURLAdapter('.js-scroll-adapter'),
      menu: new Menu('.js-menu'),
      pagetop: new Pagetop(),
      cookieDialog: new CookieDialog('.js-cookieDialog'),
      turnTable: new TurnTable('.js-turnTable'),
      simpleCopyGuard: isConfirm ? null : new SimpleCopyGuard('img'),
    };

    // ページに対応するDynamic Importモジュールを確認
    const page = this.pages[current] || false;

    // グローバルモジュールをprojectインスタンスに追加
    project.addModules(this.globals);

    // 現在ページ用のmodule郡をDynamic Importしてインスタンス化
    if (page) {
      this.currentPage = await this.importer(current);
    }

    PubSub.publish('App.ready');
    document.body.classList.add('is-app-ready');
  }

  importer(filename: string) {
    return (
      import(`./pages/${filename}.js`)
        /* eslint-disable-next-line new-cap */
        .then((Module) => new Module.default('.js-root'))
        .catch((err) => {
          console.error(err);
        })
    );
  }
}

export default new App();
