import SweetScroll from 'sweet-scroll';

/**
 * SweetScroll
 * @doc https://www.npmjs.com/package/sweet-scroll#3-initialize-sweetscroll
 */
export default class SmoothScroll {
  constructor() {
    this.instance = new SweetScroll({
      trigger: 'a[href^="#"]',
      duration: 800,
      easing: 'easeOutQuint',
      updateURL: true,
    });
  }

  get scroller() {
    return this.instance;
  }
}
