import type { SetupWorkerApi } from 'msw-es-setup/types';
import createMockServer from 'msw-es-setup';
import { handlers } from './handlers';

export default async () => {
  const setup = await createMockServer();

  if (import.meta.env.PUBLIC_API_MOCKING !== 'true') {
    return;
  }

  if (typeof window === 'undefined') {
    // for Node.js
    setup(...handlers);
  } else {
    // for Client
    // eslint-disable-next-line no-console
    console.info('\u001b[32m[MSW] Initialized. started intercepting api requests.');
    const worker = setup(...handlers);
    (worker as SetupWorkerApi).start({
      // https://mswjs.io/docs/api/setup-worker/start#onunhandledrequest
      onUnhandledRequest(req: any) {
        if (import.meta.env.PUBLIC_API_MOCKING_DEBUG === 'true') {
          console.error('[MSW] Found an unhandled %s request to %s', req.method, req.url.href);
        }
      },
    });
  }
};
