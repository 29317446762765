import type { WP_Post } from 'wp-types';
import type { WindowState } from '@/js/project';
import PubSub from 'vanilla-pubsub';
import { useGesture } from '@use-gesture/react';
import React, { useState, useEffect, useRef, RefObject, createRef } from 'react';
import menuData from '@/data/menu.yml';
import project from '@/js/project';
import gsap from 'gsap';

export type StateProps = {
  maxLength: number;
  currentIndex: number;
  rotate: number;
};

export type MenuProps = {
  href: string;
  text: string;
  color: {
    text?: string;
    bg?: string;
  };
  children: MenuProps[];
};

export type TurnTableProps = {
  posts: WP_Post[];
};

type ControlProps = {
  state: StateProps;
  setState: React.Dispatch<React.SetStateAction<StateProps>>;
};

type ConfigProps = {
  per: number;
  circleSize: number;
};

export const TurnTable = (props?: TurnTableProps) => {
  const leftSwipeContainerRef = useRef<HTMLDivElement>(null);
  const rightSwipeContainerRef = useRef<RefObject<HTMLDivElement>[]>([]);

  /**
   * @doc メニューデータを静的なymlから取得
   */
  const menu = [...menuData].map((it) => {
    if (it.text !== 'Articles') return it;
    it.children = props?.posts?.length
      ? [
          {
            // @ts-ignore
            href: new URL(props.posts[0].link).pathname,
            // @ts-ignore
            thumbnailUrl: props.posts[0].thumbnail_url,
            // @ts-ignore
            text: props.posts[0].title.rendered,
          },
        ]
      : [];
    return it;
  }) as MenuProps[];

  const [isDragging, setIsDragging] = useState<boolean>(false);

  /**
   * @doc drag処理のバインド
   */
  const bind = useGesture(
    {
      onDrag: (s) => {
        s.event.preventDefault();
        const target = s.currentTarget as HTMLElement;
        const disk = target.dataset.disk;

        setIsDragging(true);
        target.style.touchAction = 'none';
        const baseRotate = Number(target.style.cssText.match(/rotate:\s?(.*)deg;/)?.[1] || 0);
        const distance = s.delta[1] * (disk === 'left' ? -0.6 : 0.6);
        target.setAttribute('style', `--rotate: ${baseRotate + distance}deg;`);
      },
      onDragStart: (s) => {
        s.event.preventDefault();
      },
      onDragEnd: (s) => {
        s.event.preventDefault();
        const target = s.currentTarget as HTMLElement;
        const disk = target.dataset.disk;
        const state = disk === 'left' ? leftState : rightState;
        const setState = disk === 'left' ? setLeftState : setRightState;

        target.style.touchAction = '';

        /**
         * @doc 項目が1件以下のときはリセット
         */
        if (state.maxLength < 2) {
          if (state.currentIndex % config.per) {
            target.setAttribute('style', `--rotate: ${getRotatePerLength(state.currentIndex, config.per)}deg;`);
          } else {
            gsap.timeline().to(target, { rotate: '0deg', duration: 0.4 });
            target.style.transform = '';
          }
          setIsDragging(false);
          return;
        }

        /**
         * @doc 無回転（クリック・タップのみ）の場合、スキップ
         * 若干のマウスの動きにoffsetを設ける場合、=== 0 を変更
         * TODO: x軸のみのマウス移動
         */
        if (s._delta[1] === 0) {
          setIsDragging(false);
          return;
        }
        // if ((s._delta[1] === 0 || s.axis === 'x') && (s.target as HTMLElement).tagName === 'div') {
        //   if (state.currentIndex % config.per) {
        //     target.setAttribute('style', `--rotate: ${getRotatePerLength(state.currentIndex, config.per)}deg;`);
        //   } else {
        //     gsap.timeline().to(target, { rotate: '0deg', duration: 0.4 });
        //     target.style.transform = '';
        //   }
        //   setState({
        //     ...state,
        //     rotate: getRotatePerLength(state.currentIndex, config.per),
        //   });
        //   setIsDragging(false);
        //   return;
        // }

        const down = disk === 'left' ? s._delta[1] < 0 : s._delta[1] >= 0;

        if (down) {
          handlePrev({ state, setState });
        } else {
          handleNext({ state, setState });
        }
        setIsDragging(false);
      },
    },
    {}
  );

  const [config, setConfig] = useState<ConfigProps>({
    per: 6, // 1周の要素数
    circleSize: project.$window.isMobile ? 80 : 120, // 円の直径
  });
  const [childMenu, setChildMenu] = useState<MenuProps[][]>([]);

  const [leftState, setLeftState] = useState<StateProps>({
    currentIndex: 0,
    rotate: 0,
    maxLength: menu.length,
  });
  const [rightState, setRightState] = useState<StateProps>({
    currentIndex: 0,
    rotate: 0,
    maxLength: childMenu[leftState.currentIndex]?.length || 0,
  });

  /**
   * @doc ２階層目に属する子メニューのrefを作成（個別のディスクとなる）
   */
  childMenu.map((_, i) => {
    rightSwipeContainerRef.current[i] = createRef<HTMLDivElement>();
  });

  const getRotate = ({ state }: { state: StateProps }) => {
    return {
      '--rotate': `${state.rotate}deg`,
    } as React.CSSProperties;
  };

  const getRotatePerLength = (index: number, per: number): number => {
    return (index / per) * -360;
  };

  /**
   * @doc メニュー毎のディスクカラーと文字色
   */
  const getSubColor = ({ index }: { index: number }) => {
    return {
      ...(menu[index].color?.text && { '--color': `${menu[index].color.text}` }),
      ...(menu[index].color?.bg && { '--bg': `${menu[index].color.bg}` }),
    } as React.CSSProperties;
  };

  /**
   * @doc 角度と位置の計算
   */
  const getPos = ({ index, angle = 0 }: { index: number; angle?: number }) => {
    const offset = project.$window.isMobile ? 1 : 0.8;
    const len = 6;
    const r = (index / len) * Math.PI * -2 + angle;
    const cX = (config.circleSize / 2) * Math.cos(r) * offset;
    const cY = (config.circleSize / 2) * Math.sin(r) * offset;
    return {
      '--rotate': `${getRotatePerLength(index, config.per)}deg`,
      '--x': `${cX}px`,
      '--y': `${cY}px`,
    } as React.CSSProperties;
  };

  const handlePrev = ({ state, setState }: ControlProps) => {
    const currentIndex = state.currentIndex - 1 < 0 ? state.maxLength - 1 : state.currentIndex - 1;
    setState({
      ...state,
      currentIndex,
    });
  };

  const handleNext = async ({ state, setState }: ControlProps) => {
    const currentIndex = (state.currentIndex + 1) % state.maxLength;
    setState({
      ...state,
      currentIndex,
    });
  };

  /**
   * @doc 遷移イベント時
   */
  const handleTransition =
    ({ index, state, setState }: ControlProps & { index: number }) =>
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      const target = event.currentTarget;
      const isDisk = target.dataset?.disk;
      const disk = isDisk ? target : (target.closest('[data-disk]') as HTMLElement | null);

      if (disk) {
        disk.style.transform = '';
      }

      if (index === state.currentIndex) {
        location.href = target.href;
      } else {
        setState({
          ...state,
          rotate: getRotatePerLength(index, config.per),
          currentIndex: index,
        });
      }
    };

  const handlePlay = ({ state, data }: { state: StateProps; data: MenuProps[] }) => {
    const target = data[state.currentIndex];
    location.href = target.href;
  };

  const updateChildMenu = () => {
    setChildMenu(menu.map((it) => it.children).filter(Boolean));
  };

  const onChangeViewport = (state: WindowState) => {
    setConfig({
      ...config,
      circleSize: state.isMobile ? 80 : 120, // 円の直径
    });
  };

  /**
   * @doc 左ディスクのインデックス更新時、draggableによる自由角度の削除と、角度の自動フィット
   */
  useEffect(() => {
    const leftDisk = leftSwipeContainerRef.current;
    /**
     * @doc 親項目変更時、子項目の回転をリセット
     */
    setRightState({
      ...rightState,
      rotate: 0,
      currentIndex: 0,
      maxLength: childMenu[leftState.currentIndex]?.length || 0,
    });
    if (!leftDisk) {
      return;
    }
    leftDisk.style.transform = '';

    if (leftState.currentIndex % config.per) {
      setLeftState({
        ...leftState,
        rotate: getRotatePerLength(leftState.currentIndex, config.per),
      });
    } else {
      gsap.timeline().to(leftDisk, { rotate: '0deg', duration: 0.4 });
      setLeftState({
        ...leftState,
        rotate: 0,
      });
    }
  }, [leftState.currentIndex]);

  /**
   * @doc 右ディスクのインデックス更新時、draggableによる自由角度の削除と、角度の自動フィット
   */
  useEffect(() => {
    const rightDisk = rightSwipeContainerRef.current[leftState.currentIndex]?.current;
    if (!rightDisk) {
      return;
    }
    rightDisk.style.transform = '';

    if (rightState.currentIndex % config.per) {
      setRightState({
        ...rightState,
        rotate: getRotatePerLength(rightState.currentIndex, config.per),
      });
    } else {
      gsap.timeline().to(rightDisk, { rotate: '0deg', duration: 0.4 });
      setRightState({
        ...rightState,
        rotate: 0,
      });
    }
  }, [rightState.currentIndex]);

  useEffect(() => {
    updateChildMenu();
    PubSub.subscribe('App.changeViewport', onChangeViewport);
  }, []);

  useEffect(() => {
    rightSwipeContainerRef.current.forEach((it) => {
      it.current?.setAttribute('style', '--rotate: 0deg;');
    });
  }, [leftState.currentIndex]);

  return (
    <div className={['p-turnTable', isDragging ? '-dragging' : ''].join(' ')} style={{ touchAction: 'none' }}>
      <div className="p-turnTable__content">
        <div className="p-turnTable__disc u-effect" data-effect-type="left-disc">
          <div
            {...bind()}
            ref={leftSwipeContainerRef}
            className="p-turnTable__discMain"
            style={{
              ...getRotate({ state: leftState }),
              touchAction: isDragging ? 'none' : '',
            }}
            data-disk="left"
          >
            {(menu || []).map((it: any, i: number) => (
              <a
                key={`left-${i}`}
                href={it.href}
                className={`p-turnTable__link${leftState.currentIndex === i ? ' -current' : ''}`}
                style={getPos({ index: i })}
                onClick={handleTransition({ index: i, state: leftState, setState: setLeftState })}
              >
                <span className="p-turnTable__linkIcon iconfont-chevron-right"></span>
                <span className="p-turnTable__linkTextWrap">
                  <span
                    className="p-turnTable__linkText u-font-en"
                    dangerouslySetInnerHTML={{ __html: it.text }}
                  ></span>
                </span>
              </a>
            ))}
          </div>
          <div className="p-turnTable__discNav u-effect" data-effect-type="menu-ui">
            <div className="p-turnTable__control">
              <button
                type="button"
                className="p-turnTable__button iconfont-control-prev"
                onClick={() => handlePrev({ state: leftState, setState: setLeftState })}
              ></button>
              <button
                type="button"
                className="p-turnTable__button -play iconfont-control-play"
                onClick={() => handlePlay({ state: leftState, data: menu })}
              ></button>
              <button
                type="button"
                className="p-turnTable__button iconfont-control-next"
                onClick={() => handleNext({ state: leftState, setState: setLeftState })}
              ></button>
            </div>
          </div>
        </div>
        <a href="/" className="p-turnTable__button -home iconfont-home"></a>

        <div className="p-turnTable__discWrap">
          <div className="p-turnTable__discInner">
            {childMenu.map((menu: any, i: number) => (
              <div
                key={i}
                className={['p-turnTable__discEffect', i === leftState.currentIndex ? '-current' : ''].join(' ')}
              >
                <div
                  className={['p-turnTable__disc', '-sub', 'u-effect'].join(' ')}
                  data-effect-type="right-disc"
                  style={getSubColor({ index: i })}
                >
                  <div
                    {...bind()}
                    ref={rightSwipeContainerRef.current[i]}
                    className="p-turnTable__discMain"
                    style={{ ...getRotate({ state: rightState }) }}
                    data-disk="right"
                  >
                    {menu.map((it: any, j: number) => (
                      <a
                        key={`${i}-${j}`}
                        href={it.href}
                        className={`p-turnTable__link${rightState.currentIndex === j ? ' -current' : ''}`}
                        style={getPos({ index: j, angle: Math.PI })}
                        onClick={handleTransition({ index: j, state: rightState, setState: setRightState })}
                      >
                        <span className="p-turnTable__linkIcon iconfont-chevron-right"></span>
                        <span
                          className={[
                            'p-turnTable__linkTextWrap',
                            it.thumbnailUrl ? '-with-thumbnail' : '',
                            'u-font-ja',
                          ].join(' ')}
                        >
                          {it.thumbnailUrl && (
                            <>
                              <span className="p-turnTable__linkCategory u-font-en">LATEST</span>
                              <span className="p-turnTable__linkThumbnail">
                                <img src={it.thumbnailUrl} />
                              </span>
                            </>
                          )}
                          <span className="p-turnTable__linkText" dangerouslySetInnerHTML={{ __html: it.text }} />
                        </span>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="p-turnTable__discNav u-effect" data-effect-type="menu-ui">
            <div className="p-turnTable__control">
              <button
                type="button"
                className="p-turnTable__button iconfont-control-prev"
                onClick={() => handlePrev({ state: rightState, setState: setRightState })}
                disabled={childMenu[leftState.currentIndex]?.length < 2}
              ></button>
              <button
                type="button"
                className="p-turnTable__button -play iconfont-control-play"
                onClick={() => handlePlay({ state: rightState, data: childMenu[leftState.currentIndex] })}
                disabled={!childMenu[leftState.currentIndex]?.length}
              ></button>
              <button
                type="button"
                className="p-turnTable__button iconfont-control-next"
                onClick={() => handleNext({ state: rightState, setState: setRightState })}
                disabled={childMenu[leftState.currentIndex]?.length < 2}
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
