/**
 * getDataOption
 * @param {HTMLElement} target
 * @param {string} name
 */
export function getDataOption(target, name) {
  const result = {};

  try {
    Object.assign(result, target.dataset[name] ? JSON.parse(target.dataset[name]) : {});
  } catch (err) {
    console.error(err);
  }
  return result;
}
