/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { RefObject } from 'react';
import type { AnimationItem } from 'lottie-web';
import React, { createRef, useState, useEffect, useRef, useCallback } from 'react';
import { useIsVisible } from 'react-is-visible';
import PubSub from 'vanilla-pubsub';
import lottie from 'lottie-web';
import { wait } from '@/js/utils/event';
import { useInterval } from '@/js/hooks/useInterval';

import style from './TaglineAnimation.module.scss';

import namisenAnimation from '@/assets/lottie/namisen.json';
import yajirushiAnimation from '@/assets/lottie/yajirushi.json';
import fukidashiAnimation from '@/assets/lottie/fukidashi.json';

const animationData = [namisenAnimation, yajirushiAnimation, fukidashiAnimation];

const tagline = [
  {
    text: 'Culture,',
    id: 'culture',
    color: '#E0D32E',
    copy: '可能性と、旅に出よう。',
  },
  {
    text: 'Future,',
    id: 'future',
    color: '#28B1DB',
    copy: '未知との出会いが待っている。',
  },
  {
    text: 'Adventure',
    id: 'adventure',
    color: '#15D6A0',
    copy: 'まだ見ぬ風を、探しにいこう。',
  },
];

const options = {
  interval: 8100,
  transitionDuration: 800,
  writingDuration: [1.5, 1.8, 1],
};

export const TaglineAnimation = () => {
  const [time, setTime] = useState(options.interval);
  const update = useCallback((t) => setTime(t), []);
  const [state, { start, stop }] = useInterval(() => update(time - options.interval), options.interval);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [animations, setAnimation] = useState<AnimationItem[]>([]);
  const [resizing, setResizing] = useState(false);
  const copyRef = useRef<HTMLDivElement>(null);
  const decoRef = useRef<RefObject<HTMLDivElement>[]>([]);

  const isVisible = useIsVisible(copyRef);

  animationData.forEach((_, i) => {
    decoRef.current[i] = createRef<HTMLDivElement>();
  });

  useEffect(() => {
    if (time <= 0 && isVisible && !resizing && document.visibilityState === 'visible') {
      transitionEffect();
      setTime(options.interval);
    }
  }, [time, stop, isVisible, resizing]);

  useEffect(() => {
    if (!isVisible || resizing) {
      setTime(options.interval);
    }
  }, [time, isVisible, resizing]);

  useEffect(() => {
    if (isVisible) {
      state === 'STOPPED' && start();
    } else {
      state === 'RUNNING' && stop();
    }
  }, [isVisible]);

  useEffect(() => {
    if (animations.length < animationData.length) {
      return;
    }
    const prevIndex = getPrevIndex();
    // animations[prevIndex].setSpeed(4);
    animations[prevIndex].setSpeed(-4);
    // animations[prevIndex].setDirection(-1);
    animations[prevIndex].play();

    animations[currentIndex].setSpeed(options.writingDuration[currentIndex]);
    animations[currentIndex].setDirection(1);
    animations[currentIndex].play();
  }, [currentIndex, animations]);

  useEffect(() => {
    if (decoRef.current.length < animationData.length) {
      return;
    }
    let timeoutId: any;
    const animationItems = animationData.map((data, i) => {
      const container = decoRef.current[i].current!;
      return lottie.loadAnimation({
        container,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: data,
      });
    });
    const handleResize = () => {
      setResizing(true);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setResizing(false), 500);
    };
    window.addEventListener('resize', handleResize);
    document.body.classList.add('is-tagline-ready');

    PubSub.subscribe('TaglineCarousel.ready', () => {
      setAnimation(animationItems);
    });

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const transitionEffect = async () => {
    const newIndex = (currentIndex + 1) % tagline.length;

    copyRef.current?.classList.add('is-out');
    PubSub.publish('TaglineCarousel.slideTo', { id: tagline[newIndex].id });

    await wait(options.transitionDuration);

    setCurrentIndex(newIndex);

    copyRef.current?.classList.remove('is-out');
    copyRef.current?.classList.add('is-in');

    await wait(options.transitionDuration);

    copyRef.current?.classList.remove('is-in');
  };

  const getPrevIndex = () => {
    return currentIndex - 1 < 0 ? animationData.length - 1 : currentIndex - 1;
  };

  const getStateCSS = () => {
    return {
      '--stroke': tagline[currentIndex].color,
      '--current-index': currentIndex + 1,
    } as React.CSSProperties;
  };

  const copyEffectCSS = () => {
    return {
      '--duration': `${options.transitionDuration}ms`,
    } as React.CSSProperties;
  };

  return (
    <div style={getStateCSS()}>
      <div>
        {tagline.map((it, i) => (
          <div key={i} className={style.item} data-index={i} data-state={currentIndex === i && 'current'}>
            <p className={`${style.text} u-font-en`}>{it.text}</p>
            <div
              ref={decoRef.current[i]}
              className={style.decoration}
              data-state={(currentIndex === i && 'current') || (getPrevIndex() === i && 'prev')}
            ></div>
          </div>
        ))}
      </div>
      <div ref={copyRef} style={copyEffectCSS()} className={`${style.copy} u-font-ja`}>
        <p>
          <span>{tagline[currentIndex].copy}</span>
        </p>
      </div>
    </div>
  );
};
