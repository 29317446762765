import { throttle as _throttle, debounce as _debounce } from 'throttle-debounce';
// import detectTouchEvents from 'detect-touch-events'

/**
 * wait
 */
export function wait(time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

/**
 * throttle
 */
export function throttle(func, wait = 100) {
  return _throttle(wait, func);
}

/**
 * debounce
 */
export function debounce(func, wait = 100) {
  return _debounce(wait, func);
}
