// @ts-ignore
import React from 'react';
import { render } from 'react-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/js/plugins/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export type RendererOptions = {
  useQuery?: boolean;
};

class Renderer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRenderer(component: React.VFC<any>, options?: RendererOptions) {
    const Entry = component;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const EntryRenderer = (props: any, targetElement: HTMLElement) => {
      return render(
        <>
          {options?.useQuery ? (
            <QueryClientProvider client={queryClient}>{Entry && <Entry {...props} />}</QueryClientProvider>
          ) : (
            Entry && <Entry {...props} />
          )}
        </>,
        targetElement
      );
    };

    return EntryRenderer;
  }
}

export default Renderer;
