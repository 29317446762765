import PubSub from 'vanilla-pubsub';

export default class SimpleCopyGuard {
  constructor(selector) {
    this.selector = selector || 'img,p,span,[data-protect]';

    if (this._el.length) {
      this._initialize();
    }

    this._bind();
  }

  get _el() {
    return [...document.querySelectorAll(this.selector)];
  }

  _initialize() {
    this.enable();
  }

  _bind() {
    this.enable = this.enable.bind(this);
    this.disable = this.disable.bind(this);
    PubSub.subscribe('simpleCopyGuard.update', this.enable);
    PubSub.subscribe('simpleCopyGuard.disable', this.disable);
  }

  _onContextMenu() {
    return false;
  }

  disable() {
    this._el.forEach((it) => {
      it.style.pointerEvents = '';
      it.style.userSelect = '';
      it.removeEventListener('contextmenu', this._onContextMenu);
    });
  }

  enable() {
    this._el.map((it) => {
      it.style.pointerEvents = 'none';
      it.style.userSelect = 'none';
      it.addEventListener('contextmenu', this._onContextMenu);
    });
  }
}
