import PubSub from 'vanilla-pubsub';
import project from '../project.ts';

export default class HashPosition {
  constructor() {
    if (!location.hash) {
      return;
    }

    this.hash = decodeURIComponent(location.hash);

    PubSub.subscribe('App.ready', () => {
      this._initialize();
    });
  }

  _initialize() {
    // @doc https://github.com/tsuyoshiwada/sweet-scroll#todistance-any-options-partialoptions
    project.modules.smoothScroll.scroller.to(this.hash);
  }
}
