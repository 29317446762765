import PubSub from 'vanilla-pubsub';
import project from '../project.ts';

export default class Pagetop {
  constructor() {
    this.el = document.querySelector('.js-pagetop');

    if (this.el) {
      this.bind();
      this._initialize();
    }
  }

  _initialize() {
    this.el.addEventListener('click', this.move);
  }

  move() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  activate() {
    const scrollY = project.$window.scrollY;
    if (scrollY > 500) {
      this.el.classList.add('is-active');
    } else {
      this.el.classList.remove('is-active');
    }
  }

  bind() {
    this.activate = this.activate.bind(this);
    PubSub.subscribe('App.scroll', this.activate);
  }
}
