import type { WP_Post } from 'wp-types';
import Renderer from '@/js/plugins/react-renderer';
// import { getPosts } from '@/js/api/post';
import { TurnTable } from './TurnTable';

export default class {
  el: HTMLElement | null = null;

  constructor(selector: string) {
    this.el = document.querySelector(selector);

    if (this.el) {
      this.setup();
    }
  }

  setup() {
    // const res = await getPosts().catch((err) => {
    //   console.error(err);
    // });
    // // @ts-ignore
    // const data = res ? (res.data as WP_Post[]) : [];
    // @ts-ignore
    const data = [window.__LATEST_POST__];
    const RendererInstance = new Renderer();
    const render = RendererInstance.getRenderer(TurnTable);

    if (this.el) {
      render(
        {
          posts: data,
        },
        this.el
      );
    }
  }
}
