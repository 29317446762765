import type { GetPostDTO } from '@/js/api/post';
import { rest } from 'msw-es-setup';
import { postsFactory } from '../factories/post';
import { ENDPOINTS } from '@/js/constants';

export const postHandlers = [
  rest.get<GetPostDTO>(`${ENDPOINTS.WP_REST_ROOT}${ENDPOINTS.POSTS}/`, (req, res, ctx) => {
    return res(ctx.json(postsFactory()));
  }),
];
