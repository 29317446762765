import PubSub from 'vanilla-pubsub';
// import type { WindowState } from '../project';
import { wait } from '../utils/event';

// type MenuProps = {
//   trigger: HTMLElement | null;
//   content: HTMLElement | null;
// };

// type MenuInstanceProps = {
//   $menu: MenuProps;
//   [key: string]: any;
// };

export default class {
  selector: string;
  trigger: null | HTMLElement;
  contents: HTMLElement[];
  menuId: string;
  scrollBarWidth: number;
  state: {
    isExpanded: boolean;
  };

  constructor(selector: string) {
    this.selector = selector;
    this.menuId = '';
    this.scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    this.state = {
      isExpanded: false,
    };

    this.trigger = null;
    this.contents = [];

    this.initialize();
  }

  initialize() {
    this.expand = this.expand.bind(this);
    this.close = this.close.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.trigger = document.querySelector(this.selector) as HTMLElement;
    this.menuId = this.trigger.dataset.menuId || '';
    this.contents = [...document.querySelectorAll(`${this.selector}-content`)] as HTMLElement[];
    this.bind();
  }

  bind() {
    if (!this.trigger) {
      return;
    }
    this.trigger.addEventListener('click', this.handleClick);
    PubSub.subscribe('App.changeViewport', () => {
      if (this.state.isExpanded) {
        this.close();
      }
    });
  }

  async handleClick() {
    if (this.state.isExpanded) {
      await this.close();
    } else {
      await this.expand();
    }
  }

  async expand() {
    if (!this.contents.length || !this.trigger) {
      return;
    }
    const content = this.contents.find((it) => it.dataset.menuId === this.menuId);

    if (!content) return;

    document.documentElement.setAttribute('data-menu-active', '');
    content.style.display = 'block';
    await wait(10);

    content.setAttribute('aria-hidden', String(false));
    this.trigger.setAttribute('aria-expanded', String(true));

    document.documentElement.setAttribute('data-menu-ready', '');

    document.documentElement.style.setProperty('--scroll_bar_width', `${this.scrollBarWidth}px`);
    document.documentElement.style.overflow = 'hidden';

    this.state.isExpanded = true;
    document.documentElement.setAttribute('data-menu-active-id', this.menuId);
    PubSub.publish('disableScroll.disable');
  }

  async close() {
    if (!this.contents.length || !this.trigger) {
      return;
    }
    const content = this.contents.find((it) => it.dataset.menuId === this.menuId);
    const durationTarget = document.querySelector('[data-menu-duration-target]');
    const duration = durationTarget ? parseFloat(getComputedStyle(durationTarget).transitionDuration) * 1000 : 10;

    if (!content) return;

    document.documentElement.setAttribute('data-menu-remove-to', '');
    content.setAttribute('aria-hidden', String(true));
    this.trigger.setAttribute('aria-expanded', String(false));
    document.documentElement.removeAttribute('data-menu-ready');

    await wait(duration);
    document.documentElement.removeAttribute('data-menu-active');
    document.documentElement.removeAttribute('data-menu-remove-to');

    content.style.display = '';

    document.documentElement.style.removeProperty('--scroll_bar_width');
    document.documentElement.style.overflow = '';

    this.state.isExpanded = false;
    document.documentElement.removeAttribute('data-menu-active-id');
    PubSub.publish('disableScroll.release');
  }
}
