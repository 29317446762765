import type { WP_Post } from 'wp-types';

export function postFactory(): WP_Post {
  return {
    id: 103,
    date: '2023-02-16T13:15:13',
    date_gmt: '2023-02-16T13:15:13',
    // @ts-ignore
    guid: {
      rendered: 'https://ponycanyon.unit-xdu.tokyo/?p=103',
    },
    modified: '2023-02-24T09:23:28',
    modified_gmt: '2023-02-24T09:23:28',
    slug: '%e3%82%a8%e3%83%b3%e3%82%bf%e3%83%a1%e6%a5%ad%e7%95%8c%e3%81%a7%e3%82%82%e4%bb%95%e4%ba%8b%e3%81%a8%e5%ad%90%e8%82%b2%e3%81%a6%e3%82%92%e4%b8%a1%e7%ab%8b%e3%81%a7%e3%81%8d%e3%82%8b%ef%bc%81%e7%8f%be',
    status: 'publish',
    type: 'post',
    link: 'https://ponycanyon.unit-xdu.tokyo/article/103',
    title: {
      rendered: 'エンタメ業界でも仕事と子育てを両立できる！現役ママ社員が会社を、社会を変えていく',
    },
    content: {
      rendered: '',
      protected: false,
    },
    excerpt: {
      rendered: '',
      protected: false,
    },
    author: 1,
    featured_media: 117,
    // @ts-ignore
    comment_status: 'open',
    // @ts-ignore
    ping_status: 'open',
    sticky: false,
    template: '',
    format: 'standard',
    meta: [],
    categories: [1],
    tags: [11, 10, 6, 15, 12],
    thumbnail_url: 'https://ponycanyon.unit-xdu.tokyo/wp/wp-content/uploads/2023/02/aiko.jpg',
    acf: [],
    _links: {
      self: [
        {
          href: 'https://ponycanyon.unit-xdu.tokyo/wp-json/wp/v2/posts/103',
        },
      ],
      collection: [
        {
          href: 'https://ponycanyon.unit-xdu.tokyo/wp-json/wp/v2/posts',
        },
      ],
      about: [
        {
          href: 'https://ponycanyon.unit-xdu.tokyo/wp-json/wp/v2/types/post',
        },
      ],
      author: [
        {
          embeddable: true,
          href: 'https://ponycanyon.unit-xdu.tokyo/wp-json/wp/v2/users/1',
        },
      ],
      replies: [
        {
          embeddable: true,
          href: 'https://ponycanyon.unit-xdu.tokyo/wp-json/wp/v2/comments?post=103',
        },
      ],
      'version-history': [
        {
          count: 15,
          href: 'https://ponycanyon.unit-xdu.tokyo/wp-json/wp/v2/posts/103/revisions',
        },
      ],
      'predecessor-version': [
        {
          id: 318,
          href: 'https://ponycanyon.unit-xdu.tokyo/wp-json/wp/v2/posts/103/revisions/318',
        },
      ],
      'wp:featuredmedia': [
        {
          embeddable: true,
          href: 'https://ponycanyon.unit-xdu.tokyo/wp-json/wp/v2/media/117',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://ponycanyon.unit-xdu.tokyo/wp-json/wp/v2/media?parent=103',
        },
      ],
      'wp:term': [
        {
          taxonomy: 'category',
          embeddable: true,
          href: 'https://ponycanyon.unit-xdu.tokyo/wp-json/wp/v2/categories?post=103',
        },
        {
          taxonomy: 'post_tag',
          embeddable: true,
          href: 'https://ponycanyon.unit-xdu.tokyo/wp-json/wp/v2/tags?post=103',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  };
}

export function postsFactory(): WP_Post[] {
  return [...Array(6)].map(() => postFactory());
}
