/**
 * レイアウト変更のしきい値
 */
export const BREAK_POINT = 959 as const;

/**
 * 言語
 */
export const LOCALE = document.documentElement.lang || ('ja' as const);

/**
 * Cookies
 */
export const COOKIE = {
  TERMS: 'pc.cookie.policy.agree',
} as const;

/**
 * CSS Easings
 */
export const EASING = {} as const;

/**
 * API ENDPOINTS
 */
export const ENDPOINTS = {
  WP_REST_ROOT: '/wp-json/wp/v2',
  POSTS: '/posts',
} as const;
