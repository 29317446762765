import PubSub from 'vanilla-pubsub';
import { getDataOption } from '@/js/utils/dom';

export default class IntersectionEvent {
  constructor(selector, options = {}) {
    this.el = [...document.querySelectorAll(selector)];
    this.$initEntries = [];

    // デフォルトオプション
    this.options = {
      // rootMargin: '70px',
      threshold: 0.4,
      publishName: 'intersectionEvent.intersect',
      ...options,
    };

    this._bind();

    if (!this.el.length) {
      return;
    }
    this._initialize();
  }

  get initEntries() {
    return this.$initEntries;
  }

  _initialize(targets) {
    this.instances = (targets || this.el).map((el) => {
      const options = Object.assign({ ...this.options }, getDataOption(el, 'options'));

      const observer = new IntersectionObserver(this._callback, options);
      observer.observe(el);
      // console.log(observer.takeRecords())

      return { el, observer };
    });

    return this.instances;
  }

  _bind() {
    this._callback = this._callback.bind(this);
    this.unObserve = this.unObserve.bind(this);
    this.watch = this.watch.bind(this);
  }

  _callback(entries) {
    if (this.$initEntries.length < this.el.length) {
      this.$initEntries.push(entries);
    }

    entries.forEach((entry) => {
      PubSub.publish(this.options.publishName, entry);
    });
  }

  watch(selector) {
    const targets = [...document.querySelectorAll(selector)];
    if (!targets.length) {
      console.error('[IntersectionEvent] No target element for selector.');
      return;
    }

    return this._initialize(targets);
  }

  unObserve() {
    this.instances.forEach(({ observer }) => {
      // observer.unobserve(el)
      observer.disconnect();
    });
  }
}
